@import "/src/style/variables.scss";

.btn {
  font-size: 21px;
  padding: 8px 25px;
  border-radius: 6px;
  background-color: transparent;
  border: 1.3px solid $text-brand-color;
  cursor: pointer;
  &.secondary {
    background-color: $text-brand-color;
    color: $brand-color;
    cursor: pointer;
  }
}
.btn:hover {
  transform: scale(0.97);
  color: $text-brand-color;
  background-color: $brand-color;
}
